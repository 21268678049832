import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-nano-api"
    }}>{`What is Nano API?`}</h1>
    <p>{`Nano API is a novel SaaS tool used for converting a backend web API from a single service to a swarm of Nanoservices.`}</p>
    <h2 {...{
      "id": "what-is-a-nanoservice"
    }}>{`What is a Nanoservice?`}</h2>
    <p>{`A Nanoservice is a backend service that is responsible for a single API endpoint. This is in contrast to a monolithic webserver, which is responsible for all endpoints.`}</p>
    <h2 {...{
      "id": "how-does-nano-api-work"
    }}>{`How Does Nano API Work?`}</h2>
    <p>{`Nano API works by taking a server codebase and converting it into a series of Nanoservices at deploy time. These Nanoservices are small, efficient, highly-scalable, and energy-efficient `}{`[Source: NanoAPI Whitepaper]`}{`.`}</p>
    <h2 {...{
      "id": "what-it-does"
    }}>{`What it Does`}</h2>
    <p>{`Still confused? We don't blame you. Let's say that you have the following NodeJS Express server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// ./src/math.service.js
export function addition(a, b) {
    return a + b;
}

export function subtraction(a, b) {
    return a - b;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// ./src/api.js
import * as express from 'express';

import * as service from './service.js';

const app = express();
const port = 3000;

const urlBase = '/api/v2/maths';

app.use(express.json());

app.get(urlBase + '/addition', (req, res) => {
    const { body } = req;
    const result = service.addition(body.a, body.b);
    return res.json({
        result
    });
});

app.get(urlBase + '/subtraction', (req, res) => {
    const { body } = req;
    const result = service.subtraction(body.a, body.b);
    return res.json({
        result
    });
});

app.listen(port, () => {
    console.log(\`App listening on port: \${port}\`);
});
`}</code></pre>
    <p>{`If you were to take the above code and run it, you would get a webserver that provides two endpoints: `}<inlineCode parentName="p">{`/addition`}</inlineCode>{` and `}<inlineCode parentName="p">{`/subtraction`}</inlineCode>{`. `}</p>
    <p>{`This is where Nano API's build functionality comes in.`}</p>
    <p>{`Running the above code through our tool will result in 2 Nanoservices-compatible files containing everything needed to deploy this functionality to the cloud.`}</p>
    <p>{`Not only does the tool perform the transpilation for the backend code, it will also deploy the functions for you on to NanoAPIs cloud!`}</p>
    <h2 {...{
      "id": "why-use-nano-api"
    }}>{`Why Use Nano API?`}</h2>
    <p>{`The answer is twofold: performance and sustainability. Nanoservices use less energy than a normal horizontally-scaled webserver on an EC2 or other similar environment. `}</p>
    <h2 {...{
      "id": "what-are-some-use-cases"
    }}>{`What Are Some Use-Cases?`}</h2>
    <p>{`We've thought a lot about the current state of serverless computing, and we've recognized that there is a lack of a real road to adoption for existing projects, alongside some other issues serverless brings. If you've ever come across any of the following cases, Nano API is the tool for you:`}</p>
    <h3 {...{
      "id": "aggregate-simple-services"
    }}>{`Aggregate Simple Services`}</h3>
    <p>{`Many companies nowadays have integrations with service providers like Slack, GitHub, Gitlab, and others. Often, these tools are written as one-off codebases that get stuck in a corner somewhere and lose developer focus.`}</p>
    <p>{`With Nano API you could bundle these integrations together as a single Web Framework codebase, and deploy them separately.`}</p>
    <h3 {...{
      "id": "hire-devs-with-industry-standard-knowledge"
    }}>{`Hire Devs with Industry-Standard Knowledge`}</h3>
    <p>{`As in the above case, having lambda (or other serverless functions) in one codebase simplifies maintenance for all of your separate functions. As an added benefit, this means you can hire developers with industry-standard knowledge in a given Web Framework, and use that framework to write and support your infrastructure. No more need to find AWS specialists or hire contractors to setup your AWS account and functions.`}</p>
    <h3 {...{
      "id": "break-apart-expensive-monoliths"
    }}>{`Break Apart Expensive Monoliths`}</h3>
    <p>{`Anyone involved in hosting a large NodeJS, Python, or other runtime-language backend application knows that, as the codebase grows in size and complexity, so too does the cost of running enough instances to meet customer needs.`}</p>
    <p>{`For instance, in both NodeJS and Python applications, they actually suffer a decrease in performance as the codebase size increases. This is why tree-shaking is so important for these languages, and why Nano API gives it to you out of the box!`}</p>
    <h2 {...{
      "id": "whats-next-how-do-i-get-setup"
    }}>{`What's Next? How Do I Get Setup?`}</h2>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://docs.nanoapi.io/getting-started"
      }}>{`Getting Started Guide`}</a>{` to learn how to use Nano API in your project, or start directly with the `}<a parentName="p" {...{
        "href": "https://docs.nanoapi.io/tutorial/simple-api"
      }}>{`Simple Math API tutorial`}</a>{`. We're excited to see what you build with it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      